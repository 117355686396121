<template>
  <v-sheet  dark :color="wsDARKER" class="d-flex align-center justify-center">
    <div>
      <h1 class="text-center">WeStudy University</h1>
      <h3 class="font-weight-regular text-center">Coming soon ...</h3>
    </div>

  </v-sheet>
</template>

<script>

export default {
  name: "PageHomepage",
  components : {
  },
  data: ()=> ({
    displayDialog : false,
    loading : false,
    leadDialog : false,
  }),

  methods : {

  },


}
</script>

<style lang="scss" scoped>
.main_section {
  width:1100px
}
</style>